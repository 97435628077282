var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "533px"
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (_, slot) {
      return {
        key: slot,
        fn: function fn(scope) {
          return [_vm._t(slot, null, null, scope)];
        }
      };
    })], null, true),
    model: {
      value: _vm.activate,
      callback: function callback($$v) {
        _vm.activate = $$v;
      },
      expression: "activate"
    }
  }, [_c('v-card', {
    staticClass: "font-Noto-Sans"
  }, [_c('CardHeader', [_c('template', {
    slot: "left"
  }, [_c('div', {
    staticClass: "pl-8 pt-5 pb-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_inventory_settings")) + " ")])])], 2), _c('div', {
    staticClass: "pt-8 pl-8 pr-9"
  }, [_c('div', {
    staticClass: "d-flex align-center pb-4 font-14px text-light-grey"
  }, [_vm.prevItem ? _c('div', {
    staticClass: " mr-7"
  }, [_vm._v(" " + _vm._s(_vm.prevItem.year_month) + _vm._s(_vm.$t("page_inventory_setting_amount")) + " ")]) : _vm._e(), _c('div', {}, [_vm._v(_vm._s(_vm.$t("page_inventory_setting_prev_month")))])]), _c('div', {
    staticClass: "font-16px prev-month d-flex pb-11 justify-space-between"
  }, [_c('div', {
    staticClass: "d-flex align-center items-center"
  }, [_c('div', {
    staticClass: "text-light-grey mr-6"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_inventory_setting_food")) + " ")]), _vm.prevItem ? _c('div', {
    staticClass: "text-a0a0a0"
  }, [_vm._v(" ¥ " + _vm._s(parseInt(_vm.prevItem.this_month_food).toLocaleString()) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-center items-center"
  }, [_c('div', {
    staticClass: "text-light-grey mr-6"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_inventory_setting_drink")) + " ")]), _vm.prevItem ? _c('div', {
    staticClass: "text-a0a0a0"
  }, [_vm._v(" ¥ " + _vm._s(parseInt(_vm.prevItem.this_month_drink).toLocaleString()) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "d-flex justify-start font-14px text-light-grey pb-4"
  }, [_c('div', {}, [_vm._v(" " + _vm._s(this.item.year_month) + _vm._s(_vm.$t("page_inventory_setting_amount")) + " ")])])]), _c('div', {
    staticClass: "d-flex justify-space-between pl-8 pr-9"
  }, _vm._l(this.fields, function (field, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-baseline"
    }, [_c('div', {
      staticClass: "mb-2 mr-6 gray"
    }, [_vm._v(" " + _vm._s(field.label) + " ")]), _c('div', {
      staticClass: "input-currency"
    }, [_c('InputCurrency', {
      attrs: {
        "hide-details": "",
        "autocomplete": "chrome-off",
        "persistent-hint": "",
        "custom": true,
        "type": "number"
      },
      model: {
        value: field.value,
        callback: function callback($$v) {
          _vm.$set(field, "value", _vm._n($$v));
        },
        expression: "field.value"
      }
    })], 1)]);
  }), 0), _c('v-card-actions', {
    staticClass: "pt-6 pb-6"
  }, [_c('v-spacer'), _c('div', {
    staticClass: " d-flex align-center"
  }, [_c('v-btn', {
    staticClass: "text-light-grey mr-4",
    attrs: {
      "width": "101px",
      "height": "31px",
      "depressed": "",
      "color": "transparent"
    },
    on: {
      "click": function click($event) {
        _vm.activate = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("cancel")))]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "width": "101px",
      "height": "31px"
    },
    on: {
      "click": _vm.updateInventory
    }
  }, [_vm._v(_vm._s(_vm.$t("keep")))])], 1), _c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }