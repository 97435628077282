var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-text-field', _vm._g(_vm._b({
    attrs: {
      "type": _vm.isFocused ? 'tel' : 'text',
      "min": "0",
      "inputmode": "number"
    },
    on: {
      "blur": function blur($event) {
        _vm.isFocused = false;

        _vm.$emit('input-lose-focus');
      },
      "focus": function focus($event) {
        _vm.isFocused = true;
      },
      "keypress": function keypress($event) {
        return _vm.isNumberKey($event);
      },
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && $event.keyCode !== 48) { return null; }
        return _vm.handleZero($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && $event.keyCode !== 96) { return null; }
        return _vm.handleZero($event);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete", "Del"])) { return null; }
        return _vm.handleDelete($event);
      }]
    },
    scopedSlots: _vm._u([_vm.append ? {
      key: "append-outer",
      fn: function fn() {
        return [_c('span', [_vm._v(_vm._s(_vm.append))])];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm._value,
      callback: function callback($$v) {
        _vm._value = $$v;
      },
      expression: "_value"
    }
  }, 'v-text-field', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }