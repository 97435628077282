<template>
  <div class="">
    <Parent />
    <v-card class="mt-2">
      <CardHeader>
        <template slot="left">
          <div class=" py-3 pl-5">
            <div class="d-flex align-center justify-center">
              <v-card
                color="#525252"
                flat
                height="29px"
                class="mr-2"
                width="117px"
              >
                <div class="d-flex justify-center align-center">
                  <v-icon size="14">$inventoryWhite</v-icon>
                  <div class="font-18px white--text pl-2">
                    {{ $t("page_inventory_setting_title") }}
                  </div>
                </div>
              </v-card>
              <FiscalYearDropDown
                @updatedSelectedYear="updateData"
              ></FiscalYearDropDown>
            </div>
          </div>
        </template>
      </CardHeader>
      <Table
        :loading="loading"
        :headers="modifiedHeaders"
        :items="getInventories"
        :total-records="getInventories.length"
        :footer="false"
      >
        <template v-slot:item.year_month="{ item }">
          {{ item.year_month | formatDate("YYYY/MM") }}
        </template>
        <template v-slot:item.this_month_food="{ item }">
          ¥
          {{
            item.this_month_food > 0
              ? parseInt(item.this_month_food).toLocaleString()
              : "--"
          }}
        </template>
        <template v-slot:item.this_month_drink="{ item }">
          ¥
          {{
            item.this_month_drink
              ? parseInt(item.this_month_drink).toLocaleString()
              : "--"
          }}
        </template>
        <template v-slot:item.edit="{ item }">
          <EditDialog :item="item" @reload-table="fetchData">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="mr-10" v-on="on" v-bind="attrs">$edit</v-icon>
            </template>
          </EditDialog>
        </template>
      </Table>
    </v-card>
  </div>
</template>
<script>
import Parent from "@/views/admin/Store/Index";
import CardHeader from "@/components/admin/ui/CardHeader";
import Table from "@/components/admin/partials/Table/Table";
import EditDialog from "@/components/admin/models/InventoryEditDialog";
import FiscalYearDropDown from "@/components/admin/ui/FiscalYearDropDown";
import { mapGetters } from "vuex";

export default {
  components: {
    Parent,
    CardHeader,
    Table,
    EditDialog,
    FiscalYearDropDown
  },
  data() {
    return {
      show: false,
      loading: true,
      selectedYear: null,
      yearData: null,
      headers: [
        {
          text: this.$t("page_inventory_moon"),
          value: "year_month",
          sortable: false,
          align: "center",
          width: "110"
        },
        {
          text: this.$t("page_inventory_month_inventory"),
          value: "this_month_food",
          sortable: false,
          align: "center",
          width: "110"
        },
        {
          text: this.$t("page_inventory_month_drink"),
          value: "this_month_drink",
          sortable: false,
          align: "center",
          width: "110"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getShop", "getInventories"]),
    modifiedHeaders() {
      if (this.selectedYear === 2) {
        return [
          ...this.headers,
          {
            text: "",
            value: "",
            align: "center",
            sortable: false
          }
        ];
      } else {
        return [
          ...this.headers,
          {
            text: "",
            value: "edit",
            sortable: false,
            align: "right"
          }
        ];
      }
    }
  },
  methods: {
    async fetchData() {
      await this.$store
        .dispatch("FETCH_INVENTORY_MANAGEMENT", {
          shop_id: this.$route.params.shop_id,
          year: this.yearData
        })
        .then(() => {
          this.loading = false;
        });
    },
    async updateData(year, data) {
      this.selectedYear = data.id;
      this.yearData = year;
      await this.fetchData();
    }
  }
};
</script>
<style lang="scss" src="./style.scss" scoped></style>
