var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('Parent'), _c('v-card', {
    staticClass: "mt-2"
  }, [_c('CardHeader', [_c('template', {
    slot: "left"
  }, [_c('div', {
    staticClass: " py-3 pl-5"
  }, [_c('div', {
    staticClass: "d-flex align-center justify-center"
  }, [_c('v-card', {
    staticClass: "mr-2",
    attrs: {
      "color": "#525252",
      "flat": "",
      "height": "29px",
      "width": "117px"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-center align-center"
  }, [_c('v-icon', {
    attrs: {
      "size": "14"
    }
  }, [_vm._v("$inventoryWhite")]), _c('div', {
    staticClass: "font-18px white--text pl-2"
  }, [_vm._v(" " + _vm._s(_vm.$t("page_inventory_setting_title")) + " ")])], 1)]), _c('FiscalYearDropDown', {
    on: {
      "updatedSelectedYear": _vm.updateData
    }
  })], 1)])])], 2), _c('Table', {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.modifiedHeaders,
      "items": _vm.getInventories,
      "total-records": _vm.getInventories.length,
      "footer": false
    },
    scopedSlots: _vm._u([{
      key: "item.year_month",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.year_month, "YYYY/MM")) + " ")];
      }
    }, {
      key: "item.this_month_food",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" ¥ " + _vm._s(item.this_month_food > 0 ? parseInt(item.this_month_food).toLocaleString() : "--") + " ")];
      }
    }, {
      key: "item.this_month_drink",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" ¥ " + _vm._s(item.this_month_drink ? parseInt(item.this_month_drink).toLocaleString() : "--") + " ")];
      }
    }, {
      key: "item.edit",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('EditDialog', {
          attrs: {
            "item": item
          },
          on: {
            "reload-table": _vm.fetchData
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-10"
              }, 'v-icon', attrs, false), on), [_vm._v("$edit")])];
            }
          }], null, true)
        })];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }