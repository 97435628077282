<template>
  <v-dialog v-model="activate" width="533px">
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope"
    /></template>
    <v-card class="font-Noto-Sans">
      <CardHeader>
        <template slot="left">
          <div class="pl-8 pt-5 pb-4">
            {{ $t("page_inventory_settings") }}
          </div>
        </template>
      </CardHeader>
      <div class="pt-8 pl-8 pr-9">
        <!-- prev month inventory -->
        <div class="d-flex align-center pb-4 font-14px text-light-grey">
          <div class=" mr-7" v-if="prevItem">
            {{ prevItem.year_month }}{{ $t("page_inventory_setting_amount") }}
          </div>
          <div class="">{{ $t("page_inventory_setting_prev_month") }}</div>
        </div>
        <div class="font-16px prev-month d-flex pb-11 justify-space-between">
          <div class="d-flex align-center items-center">
            <div class="text-light-grey mr-6">
              {{ $t("page_inventory_setting_food") }}
            </div>
            <div class="text-a0a0a0" v-if="prevItem">
              ¥ {{ parseInt(prevItem.this_month_food).toLocaleString() }}
            </div>
          </div>
          <div class="d-flex align-center items-center">
            <div class="text-light-grey mr-6">
              {{ $t("page_inventory_setting_drink") }}
            </div>
            <div class="text-a0a0a0" v-if="prevItem">
              ¥ {{ parseInt(prevItem.this_month_drink).toLocaleString() }}
            </div>
          </div>
        </div>
        <!-- enter your settings -->
        <div class="d-flex justify-start font-14px text-light-grey pb-4">
          <div class="">
            {{ this.item.year_month }}{{ $t("page_inventory_setting_amount") }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-space-between pl-8 pr-9">
        <div
          class="d-flex align-baseline"
          v-for="(field, index) in this.fields"
          :key="index"
        >
          <div class="mb-2 mr-6 gray">
            {{ field.label }}
          </div>
          <div class="input-currency">
            <InputCurrency
              v-model.number="field.value"
              hide-details
              autocomplete="chrome-off"
              persistent-hint
              :custom="true"
              type="number"
            ></InputCurrency>
          </div>
        </div>
      </div>
      <v-card-actions class="pt-6 pb-6">
        <v-spacer></v-spacer>
        <div class=" d-flex align-center">
          <v-btn
            class="text-light-grey mr-4"
            @click="activate = false"
            width="101px"
            height="31px"
            depressed
            color="transparent"
            >{{ $t("cancel") }}</v-btn
          >
          <v-btn
            color="primary"
            @click="updateInventory"
            width="101px"
            height="31px"
            >{{ $t("keep") }}</v-btn
          >
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import CardHeader from "@/components/admin/ui/CardHeader";
import InputCurrency from "@/components/admin/ui/Input/InputCurrency";
import dayjs from "@/plugins/dayjs";
import { mapGetters } from "vuex";
export default {
  components: {
    CardHeader,
    InputCurrency
  },
  props: {
    item: {
      requried: true,
      type: Object
    }
  },
  data() {
    return {
      activate: false,
      isCreate: false,
      prevItem: null,
      fields: {
        this_month_food: {
          label: this.$t("page_inventory_setting_food"),
          value: 0
        },
        this_month_drink: {
          label: this.$t("page_inventory_setting_drink"),
          value: 0
        }
      }
    };
  },
  computed: {
    ...mapGetters(["getShop", "getInventories"])
  },
  watch: {
    activate: function(val) {
      if (val) {
        for (let field in this.fields) {
          this.fields[field].value = parseInt(this.item[field]);
        }
        if (this.item.create !== undefined) {
          this.isCreate = this.item.create;
        }
        this.fillPrevMonth();
      }
    }
  },
  methods: {
    fillPrevMonth() {
      const prevMonthData = this.getInventories.find(
        item =>
          item.year_month ===
          dayjs(this.item.year_month)
            .subtract(1, "month")
            .format("YYYY/MM")
      );
      if (prevMonthData !== undefined) {
        this.prevItem = prevMonthData;
      }
    },
    updateInventory() {
      let emptyCounter = 0;
      let updated = {};
      for (let field in this.fields) {
        if (this.fields[field].value == 0) {
          emptyCounter++;
        } else {
          updated[field] = this.fields[field].value;
        }
      }
      if (emptyCounter === 0) {
        if (this.isCreate) {
          this.$store
            .dispatch("CREATE_INVENTORY_MANAGEMENT", {
              shop_id: this.item.shop_id,
              year_month: this.item.year_month,
              ...updated
            })
            .then(() => {
              this.activate = false;
              this.$emit("reload-table");
            });
        } else {
          this.$store
            .dispatch("UPDATE_INVENTORY_MANAGEMENT", {
              shop_id: this.item.shop_id,
              id: this.item.id,
              ...updated
            })
            .then(() => {
              this.$store.dispatch("ALERT", {
                show: true,
                text: "編集内容を保存しました。"
              });
              this.activate = false;
              this.$emit("reload-table");
            });
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.text-a0a0a0 {
  color: #a0a0a0 !important;
}
.input-currency {
  width: 136px;
}
.prev-month {
  width: 414px;
}
</style>
